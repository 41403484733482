@font-face {
	font-family: 'peatix';
	src:url('fonts/peatix.eot');
	src:url('fonts/peatix.eot?#iefix') format('embedded-opentype'),
		url('fonts/peatix.woff') format('woff'),
		url('fonts/peatix.ttf') format('truetype'),
		url('fonts/peatix.svg?#peatix') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before,
[data-icon]:after {
	font-family: 'peatix';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.icon-pod:before, .icon-pea-chan:before, .icon-history:before, .icon-stop:before, .icon-refresh:before, .icon-envelope:before, .icon-eye:before, .icon-search:before, .icon-cross:before, .icon-info:before, .icon-home:before, .icon-triangle:before, .icon-user:before, .icon-facebook:before, .icon-instagram:before, .icon-twitter:before, .icon-trashcan:before, .icon-arrow-right:before, .icon-arrow-left:before, .icon-checkmark:before, .icon-cog:before, .icon-event:before, .icon-duplicate:before, .icon-announce:before, .icon-warning:before, .icon-message:before, .icon-earth:before, .icon-users:before, .icon-template:before, .icon-pencil:before, .icon-lock:before, .icon-full-screen:before, .icon-split-screen:before, .icon-tickets:before, .icon-googleplus:before, .icon-list:before, .icon-bell:before, .icon-coupon:before, .icon-checkmark:after, .icon-arrow-right:after, .icon-triangle:after {
	font-family: 'peatix';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}
.icon-pea-chan:before {
	content: "\70";
}
.icon-history:before {
	content: "\63";
}
.icon-search:before {
	content: "\67";
}
.icon-cross:before {
	content: "\78";
}
.icon-info:before {
	content: "\69";
}
.icon-home:before {
	content: "\68";
}
.icon-triangle:before {
	content: "\5e";
}
.icon-user:before {
	content: "\75";
}
.icon-facebook:before {
	content: "\66";
}
.icon-instagram:before {
	content: "\e900";
}
.icon-twitter:before {
	content: "\74";
}
.icon-trashcan:before {
	content: "\64";
}
.icon-arrow-right:before {
	content: "\3e";
}
.icon-arrow-left:before {
	content: "\3c";
}
.icon-before.icon-checkmark:before {
	content: "\60";
}
.icon-cog:before {
	content: "\73";
}
.icon-event:before {
	content: "\65";
}
.icon-duplicate:before {
	content: "\71";
}
.icon-announce:before {
	content: "\6d";
}
.icon-warning:before {
	content: "\21";
}
.icon-message:before {
	content: "\23";
}
.icon-earth:before {
	content: "\76";
}
.icon-users:before {
	content: "\61";
}
.icon-template:before {
	content: "\26";
}
.icon-pencil:before {
	content: "\2f";
}
.icon-lock:before {
	content: "\22";
}
.icon-full-screen:before {
	content: "\46";
}
.icon-split-screen:before {
	content: "\4c";
}
.icon-tickets:before {
	content: "\54";
}
.icon-googleplus:before {
	content: "\2b";
}
.icon-stop:before {
	content: "\43";
}
.icon-refresh:before {
	content: "\72";
}
.icon-envelope:before {
	content: "\45";
}
.icon-eye:before {
	content: "\6f";
}
.icon-list:before {
	content: "\6c";
}
.icon-bell:before {
	content: "\52";
}
.icon-coupon:before {
	content: "\53";
}
.icon-pod:before {
	content: "\3a";
}
.icon-after.icon-checkmark:after {
	content: "\60";
}
.icon-after.icon-arrow-right:after {
	content: "\3e";
}
.icon-after.icon-triangle:after {
	content: "\5e";
}
.icon-after.icon-arrow-right:before,
.icon-after.icon-triangle:before
{
	content: none;
}



